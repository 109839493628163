import { Component } from '@angular/core';

@Component({
  selector: 'section-video',
  templateUrl: './section-videos.component.html',
  styleUrls: ['./section-videos.component.scss']
})
export class SectionVideosComponent {

}
