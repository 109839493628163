import { Component } from '@angular/core';

@Component({
  selector: 'section-about',
  templateUrl: './section-about.component.html',
  styleUrls: ['./section-about.component.scss']
})
export class SectionAboutComponent {

}
