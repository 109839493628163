import { Component } from '@angular/core';

@Component({
  selector: 'section-choose-area',
  templateUrl: './section-choose-area.component.html',
  styleUrls: ['./section-choose-area.component.scss']
})
export class SectionChooseAreaComponent {

}
