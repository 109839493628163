<section
  id="testimonios"
  class="testimonial-area testimonial-p pt-100 pb-70"
  style="
    background-image: url(assets/images/landing/bg/client-bg.png);
    background-repeat: no-repeat;
    background-position: right center;
  "
>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title center-align">
          <h2>What Our Clients Say’s</h2>
          <p>
            Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel
            scelerisque porttitor, leo quam vulputate nibh, sit amet blandit
            erat magna.
          </p>
        </div>
      </div>
      <div class="col-lg-12">
        <owl-carousel-o [options]="customOptions" class="testimonial-active">
          <ng-template carouselSlide class="col-xl-4">
            <div class="single-testimonial">
              <div class="testi-author text-left">
                <img
                  src="assets/images/landing/testimonial/testi_avatar.png"
                  alt="img"
                />
                <div class="ta-info">
                  <h6>Mr Jone Dose</h6>
                  <span>Author</span>
                </div>
              </div>
              <div class="qutation">
                <img
                  src="assets/images/landing/bg/qutation.png"
                  alt="qutation.png"
                />
              </div>

              <p>
                Praesent fermentum nisl at ipsum fcilisis viverra elementum
                accumsan finibus. Cras placerat lacinia mitincidunt sit amet
                venenatis.
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide class="col-xl-4">
            <div class="single-testimonial">
              <div class="testi-author text-left">
                <img
                  src="assets/images/landing/testimonial/testi_avatar2.png"
                  alt="img"
                />
                <div class="ta-info">
                  <h6>Mr Jone Dose</h6>
                  <span>Author</span>
                </div>
              </div>
              <div class="qutation">
                <img
                  src="assets/images/landing/bg/qutation.png"
                  alt="qutation.png"
                />
              </div>
              <p>
                Praesent fermentum nisl at ipsum fcilisis viverra elementum
                accumsan finibus. Cras placerat lacinia mitincidunt sit amet
                venenatis.
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide class="col-xl-4">
            <div class="single-testimonial">
              <div class="testi-author text-left">
                <img
                  src="assets/images/landing/testimonial/testi_avatar3.png"
                  alt="img"
                />
                <div class="ta-info">
                  <h6>Mr Jone Dose</h6>
                  <span>Web Developer</span>
                </div>
              </div>
              <div class="qutation">
                <img
                  src="assets/images/landing/bg/qutation.png"
                  alt="qutation.png"
                />
              </div>
              <p>
                Praesent fermentum nisl at ipsum fcilisis viverra elementum
                accumsan finibus. Cras placerat lacinia mitincidunt sit amet
                venenatis.
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide class="col-xl-4">
            <div class="single-testimonial">
              <div class="testi-author text-left">
                <img
                  src="assets/images/landing/testimonial/testi_avatar.png"
                  alt="img"
                />
                <div class="ta-info">
                  <h6>Mr Jone Dose</h6>
                  <span>Web Developer</span>
                </div>
              </div>
              <div class="qutation">
                <img
                  src="assets/images/landing/bg/qutation.png"
                  alt="qutation.png"
                />
              </div>
              <p>
                Praesent fermentum nisl at ipsum fcilisis viverra elementum
                accumsan finibus. Cras placerat lacinia mitincidunt sit amet
                venenatis.
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide class="col-xl-4">
            <div class="single-testimonial">
              <div class="testi-author text-left">
                <img
                  src="assets/images/landing/testimonial/testi_avatar2.png"
                  alt="img"
                />
                <div class="ta-info">
                  <h6>Mr Jone Dose</h6>
                  <span>Web Developer</span>
                </div>
              </div>
              <div class="qutation">
                <img
                  src="assets/images/landing/bg/qutation.png"
                  alt="qutation.png"
                />
              </div>
              <p>
                Praesent fermentum nisl at ipsum fcilisis viverra elementum
                accumsan finibus. Cras placerat lacinia mitincidunt sit amet
                venenatis.
              </p>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
