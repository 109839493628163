<!-- choose-area -->
<section
  class="choose-area pt-100 pb-60 p-relative"
  style="
    background-image: url(assets/images/landing/shape/header-sape3.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div
    class="chosse-img"
    style="background-image: url(assets/images/landing/bg/easy-m-bg.png)"
  ></div>
  <div class="chosse-img2">
    <img style="transform: translate(90px, 0px) skew(36deg, 329deg); filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.75));" src="assets/images/landing/Section-3.png" alt="mobile" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xl-5"></div>
      <div class="col-xl-7">
        <div class="choose-wrap">
          <div class="section-title w-title left-align mb-25">
            <h2>Easy and Perfect Solution For Your Unique Online Shopping</h2>
          </div>
          <div class="choose-content">
            <p>
              Welcome to the ultimate online shopping destination, where convenience meets variety, and every click brings you closer to finding your perfect item. Our user-friendly platform offers an expansive range of products with exclusive online deals and discounts, you can enjoy great savings on top-quality items.
            </p>
            <p>
              Experience the joy of shopping from the comfort of your home. Shop with us today and discover a new world of online retail, where your satisfaction is our priority, and every purchase is an opportunity for delight.
            </p>

            <div class="choose-btn mt-30">
              <a href="#"
                ><span class="icon"
                  ><img
                    src="assets/images/landing/icon/apple-icon.png"
                    alt="apple-icon"
                /></span>
                <span class="text">
                  Available in <strong>App Store</strong></span
                ></a
              >
              <a href="#" class="g-btn"
                ><span class="icon"
                  ><img
                    src="assets/images/landing/icon/g-play-icon.png"
                    alt="apple-icon"
                /></span>
                <span class="text">
                  Available in <strong>Play Store</strong></span
                ></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- choose-area-end -->
