// export layout related constants
export const LAYOUT_VERTICAL = 'vertical';

export const LAYOUT_HORIZONTAL = 'horizontal';

export const LAYOUT_WIDTH = 'boxed';

export const SIDEBAR_TYPE = 'light';

export const TOPBAR = "light";

export const LAYOUT_MODE = 'light';