import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class SharedService {
  countries: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  cities: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  mainCategories: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  subCategories: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  
  private highlightedSectionSubject = new Subject<any>();
  highlightedSection$ = this.highlightedSectionSubject.asObservable();

  constructor(private http: HttpClient) { }
  getAllCountries() {
    this.http
      .get(environment.baseUrl + "/clickz/country")
      .subscribe((data: any) => {
        this.countries.next(data);
      });
  }
  
  updateHighlightedSection(value: any) {
    this.highlightedSectionSubject.next(value);
  }

  getCountry(countryExternalId) {
    console.log("test country");
    return this.http.get(
      environment.baseUrl + `/clickz/country/${countryExternalId}`
    );
  }
  getAllCities() {
    this.http
      .get(environment.baseUrl + "/clickz/city")
      .subscribe((data: any) => {
        this.cities.next(data);
      });
  }
  getCitiesOfCountry(countryId: any) {
    return this.http.get(
      environment.baseUrl + `/clickz/city/country/${countryId}`
    );
  }
  getCity(cityExternalId) {
    return this.http.get(
      environment.baseUrl + `/clickz/city/${cityExternalId}`
    );
  }
  getAllCategories() {
    this.http
      .get(environment.baseUrl + "/clickz/category")
      .subscribe((data: any) => {
        this.mainCategories.next(data);
      });
  }
  getCategories() {
    return this.http.get(environment.baseUrl + "/clickz/category");
  }
  getCategory(categoryExternalId) {
    return this.http.get(
      environment.baseUrl + `/clickz/category/${categoryExternalId}`
    );
  }
  getSubOfCategory(categoryId) {
    return this.http.get(
      environment.baseUrl + `/clickz/sub-category/parent/${categoryId}`
    );
  }
  getSubCategory(subCategoryExternalId) {
    return this.http.get(
      environment.baseUrl +
      `/clickz/sub-category/${subCategoryExternalId}`
    );
  }
  getManufacturing() {
    return this.http.get(
      environment.baseUrl + "/clickz/manufacturing"
    );
  }
  getManufacturingByIndex(manufacturingExternalId) {
    return this.http.get(
      environment.baseUrl +
      `/clickz/manufacturing/${manufacturingExternalId}`
    );
  }
  getTurnover() {
    return this.http.get(environment.baseUrl + "/clickz/turnover");
  }
  getCurrency() {
    return this.http.get(environment.baseUrl + "/clickz/currency");
  }
  getCurrencyTurnover(turnoverCurrencyExternalId) {
    return this.http.get(
      environment.baseUrl +
      `/clickz/turnover/currency/${turnoverCurrencyExternalId}`
    );
  }
  getAmount() {
    return this.http.get(environment.baseUrl + "/clickz/amount");
  }
  getAmountById(amountExternalId) {
    return this.http.get(
      environment.baseUrl + `/clickz/amount/${amountExternalId}`
    );
  }
  getPeriodAmount(period) {
    return this.http.get(
      environment.baseUrl + `/clickz/amount/period/${period}`
    );
  }
  getProductColors() {
    return this.http.get(environment.baseUrl + "/product/color");
  }
}
