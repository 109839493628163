import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {

  formSubject = new Subject<{ form: FormGroup; submit: boolean }>();

  lettersAndNumbersRegex: string = '^[A-Za-z0-9]*$'; //regular expression letters and numbers only
  phoneRegex: string = '^0{1}1{1}([0-9]){9}$'; //regular expression phone
  numberRegex: string = '^[0-9]*$'; //regular expression phone

  mapErrors = new Map([
    ['required', 'this field is required'],
    ['email', 'this field must be an email!'],
    ['mustMatch', "this confirm password doesn't match password"],
    ['equal', "the amount is not enough for invoice price"],
    ['minlength', 'this field must be at least '],
    ['maxlength', 'this field must be at most '],
    ['min', 'this field must be at least '],
    ['max', 'this field must be at most '],
    ['InvalidEmail', 'the email field in invalid'],
    ['InvalidPassword', 'the password field is invalid'],
    ['emailExist', 'this email is already exist please choose another email'],
    ['spaces', 'this field must not contain spaces'],
    ['fileSizeVideo', 'this file must be at most 10 MB'],
    ['fileSizeImg', 'this file must be at most 5 MB'],
    ['dateFormat', 'this file must be date'],
    ['pattern', 'this field must be numbers only'],
    ['dateValid', 'start date must be before end date'],
  ]);

  constructor() { }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  isFieldValid(form: FormGroup, field: string, isSubmit: boolean) {
    let message = '';

    if ((!form.get(field)?.valid && form.get(field)?.touched) || (!form.get(field)?.valid && form.get(field)?.dirty) || (form.get(field)?.untouched && form.get(field)?.errors?.required && isSubmit)) {

      message = this.mapErrors.get(Object.keys(form.get(field).errors)[0]);

      if (form.get(field).hasError('minlength'))
        message += form.get(field).errors.minlength.requiredLength + " characters";

      if (form.get(field).hasError('maxlength'))
        message += form.get(field).errors.maxlength.requiredLength + " characters";

      if (form.get(field).hasError('min'))
        message += form.get(field).errors.min.min;

      if (form.get(field).hasError('max'))
        message += form.get(field).errors.max.max;

    }
    return message;
  }
}
