import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "../services/auth.service";
import { AuthfakeauthenticationService } from "../services/authfake.service";


@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.userValue;
    if (user) {
      switch (user.sellerStatus) {
        case "VERIFICATION": {
          this.router.navigate(["/account/verify"], {
            queryParams: {
              returnUrl: "register",
            },
          });
          break;
        }
        case "VERIFIED": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "IMAGING": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "BUSINESS": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "REVIEW": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "SELLER_REJECTED": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "SELLER_APPROVED": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
        case "WAITING_APPROVAL": {
          this.authenticationService.haveUser.next(true);
          this.router.navigate(["/account/add-store"]);
          break;
        }
      }
      // authorised so return true
      this.authenticationService.haveUser.next(true);
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/account/login"]);
    return false;
    //   if (environment.defaultauth === "firebase") {
    //     const currentUser = this.authenticationService.currentUser();
    //     if (currentUser) {
    //       // logged in so return true
    //       return true;
    //     }
    //   } else {
    //     const currentUser = this.authFackservice.currentUserValue;
    //     if (currentUser) {
    //       // logged in so return true
    //       return true;
    //     }
    //   }
    //   // not logged in so redirect to login page with the return url
    //   this.router.navigate(["/account/login"], {
    //     queryParams: { returnUrl: state.url },
    //   });
    //   return false;
  }
}
