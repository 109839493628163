import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({ providedIn: "root" })
export class UserProfileService {
  constructor(private http: HttpClient,private authService:AuthenticationService) {}

  // getAll() {
  //   return this.http.get<User[]>(`/api/login`);
  // }

  register(user: User) {
    return this.http.post(`/users/register`, user);
  }

  getUser(sellerExternalId) {
    return this.http.get(
      environment.baseUrl + `/clickz/seller/${sellerExternalId}`
    );
  }

  updateStatus(sellerExternalId) {
    return this.http.put(
      environment.baseUrl + `/clickz/seller/status`,
      {
        sellerExternalId: sellerExternalId,
        sellerStatus: 'WAITING_APPROVAL',
      }
    );
  }

  deleteSeller(sellerExternalId,loadingSubmit) {
    this.http
      .delete(environment.baseUrl + `/clickz/seller/${sellerExternalId}`)
      .subscribe(() => {
        loadingSubmit=false;
        this.authService.logout();
      });
  }
}
