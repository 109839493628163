import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent implements OnInit, OnDestroy {
  
  constructor() {
  }

  ngOnInit(): void {
    document.getElementsByTagName('body').item(0).classList.add('landing-page');
    document.getElementById('scrollUp').style.display = 'none';
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    document.getElementById('scrollUp').style.display = window.pageYOffset > 40 ? 'block' : 'none';
  }


  scrollIntoTop(): void {
    const element = document.getElementById('header');
    element.scrollIntoView();
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body').item(0).classList.remove('landing-page');
  }

}
