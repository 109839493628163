<!-- header -->
<header class="header-area" id="header">
  <div id="header-sticky" class="menu-area" #nav>
    <div class="container-fluid">
      <div class="second-menu">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-2">
            <div class="logo">
              <a routerLink="/"
                ><img src="assets/images/auth/logo.png" alt="logo" width="154"
              /></a>
            </div>
          </div>
            
          <div class="col-xl-6 col-lg-9">
            <div
              class="responsive"
              (click)="mobileMenuShown = !mobileMenuShown"
            >
              <i 
              [style.color]="mobileMenuShown ? 'black' : 'white'"
              class="icon dripicons-align-right"></i>
            </div>
            <div class="main-menu text-right text-xl-right">
              <nav
                id="mobile-menu collapsed"
                *ngIf="mobileMenuShown || !detectMobile"
                F
              >
                <ul>
                  <li class="nav-sign-in"><a routerLink="/account/login">Sign in</a></li>
                  <li class="nav-sign-in"><a routerLink="/account/register">Join us</a></li>
                  <li [ngClass]="{'active' : activeSectionId == 'home'}" class="has-sub">
                    <a (click)="scrollInto('parallax')">Home</a>
                  </li>
                  <li [ngClass]="{'active' : activeSectionId == 'aboutUs'}"><a (click)="scrollInto('about')">About Us</a></li>
                  <li [ngClass]="{'active' : activeSectionId == 'features'}"><a (click)="scrollInto('features')">Features</a></li>
                  <li [ngClass]="{'active' : activeSectionId == 'screenshot'}"><a (click)="scrollInto('screen')">Screenshort</a></li>
                  <li [ngClass]="{'active' : activeSectionId == 'contact'}"><a (click)="scrollInto('contact')">Contact</a></li>
                  <li class="has-sub">
                    <a><img
                      *ngIf="flagvalue !== undefined"
                      src="{{ flagvalue }}"
                      alt="Header Language"
                      height="16"
                    />
                    <span class="ms-1">{{ countryName }}</span>
                    <img
                      *ngIf="flagvalue === undefined"
                      src="{{ valueset }}"
                      alt="Header Language"
                      height="16"
                    />
                    <span *ngIf="flagvalue === undefined" class="ms-1">English</span></a>
                    <ul>
                      <li
                        *ngFor="let item of listLang"
                      >
                        <a
                        href="javascript:void(0);"
                        class="dropdown-item notify-item text-black"
                        (click)="setLanguage(item.text, item.lang, item.flag)"
                        [ngClass]="{ active: cookieValue === item.lang }"
                      >
                        <img
                          src="{{ item.flag }}"
                          alt="user-image"
                          class="mx-1"
                          height="12"
                        />
                        <span class="align-middle">{{ item.text }}</span>
                      </a>
                      </li>
                    </ul>
                  </li>
                  <!-- <li><a (click)="scrollInto('blog')">Blog</a></li> -->
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-4 text-right mb-2">
            <div class="d-flex gap-3 align-items-center flex-wrap nav-buttons">
              <a
              class="btn bg-secondary-500 fs-5 text-white fw-semibold rounded-pill px-5"
              routerLink="/account/login"
              >Sign In</a
              >
              <a class="btn bg-primary-500 fs-5 text-white fw-semibold rounded-pill px-5"
                routerLink="/account/register"
                >Join Us</a
              >
            </div>
            <!-- <div class="header-btn second-header-btn">
              <a class="btn" routerLink="/account/login">Sign in</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header-end -->
