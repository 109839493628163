<section
  id="screen"
  class="screen-area services-bg services-two pt-100 pb-70"
  style="
    background-image: url(assets/images/landing/shape/header-sape4.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-10">
        <div class="section-title text-center pl-40 pr-40 mb-50">
          <h2>Our App Screenshots</h2>
          <p>
            Discover most of our Mobile App, join our online community, and gain access to special promotions, early sale alerts, and more.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Swiper -->
      <div class="swiper-container">
        <div class="swiper-wrapper" style="perspective: 1000px;">
          <div class="swiper-slide" style="position: relative;">
            <img
              src="assets/images/landing/gallery/1.svg"
              style="width: 305px;"
              alt="slide 1"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="assets/images/landing/gallery/2.svg"
              style="width: 305px;"
              alt="slide 2"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="assets/images/landing/gallery/3.svg"
              style="width: 305px;"
              alt="slide 3"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="assets/images/landing/gallery/4.svg"
              style="width: 305px;"
              alt="slide 4"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="assets/images/landing/gallery/5.svg"
              style="width: 305px;"
              alt="slide 5"
            />
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination w-100">
        </div>
      </div>
    </div>
  </div>
</section>
