import { Component } from '@angular/core';

@Component({
  selector: 'section-contact',
  templateUrl: './section-contact.component.html',
  styleUrls: ['./section-contact.component.scss']
})
export class SectionContactComponent {

}
