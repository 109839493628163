import { Component } from '@angular/core';

@Component({
  selector: 'section-newsletter',
  templateUrl: './section-newsletter.component.html',
  styleUrls: ['./section-newsletter.component.scss']
})
export class SectionNewsletterComponent {

}
