<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">All Rights Reserved . Clickz {{ year }}.</div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Powered By:<a target="_blank" href="https://mydigieyes.com/">
             My DiGi Eyes Technologies
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
