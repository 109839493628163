import { Component } from '@angular/core';

@Component({
  selector: 'section-faq',
  templateUrl: './section-faq.component.html',
  styleUrls: ['./section-faq.component.scss']
})
export class SectionFaqComponent {

}
