<section
  id="pricing"
  class="pricing-area pt-100 pb-50"
  style="
    background-image: url(assets/images/landing/shape/header-sape7.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-7 col-lg-8">
        <div class="section-title text-center mb-50">
          <h2>Our Pricing Plans</h2>
          <p>
            Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel
            scelerisque porttitor, leo quam vulputate nibh, sit amet blandit
            erat magna.
          </p>
        </div>
        <nav class="pricing-tab mb-60">
          <span class="monthly_tab_title"> Monthly </span>
          <span class="pricing-tab-switcher"></span>
          <span class="annual_tab_title"> Annual </span>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="pricing-box text-center mb-60">
          <div class="pricing-head">
            <h4>Beginner</h4>
            <div class="pricing-amount">
              <div class="annual_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 95 </span>
                <span class="subscription"> / Annual </span>
              </div>
              <div class="monthly_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 15 </span>
                <span class="subscription"> / Monthly </span>
              </div>
            </div>
            <h5>I have a dream</h5>
          </div>

          <div class="pricing-body mb-40 text-left">
            <ul>
              <li>1000+ projets</li>
              <li>No transaction fees</li>
              <li>Unlimited Storage</li>
              <li>5 Download</li>
            </ul>
          </div>
          <div class="pricing-btn">
            <a href="#" class="btn">Start Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="pricing-box active text-center mb-60">
          <div class="poppuler">
            <span class="btn">Poppuler</span>
          </div>
          <div class="pricing-head">
            <h4>Starter</h4>
            <div class="pricing-amount">
              <div class="annual_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 78 </span>
                <span class="subscription"> /Annual </span>
              </div>
              <div class="monthly_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 15 </span>
                <span class="subscription"> /Monthly </span>
              </div>
            </div>
            <h5>I have a dream</h5>
          </div>
          <div class="pricing-body mb-40 text-left">
            <ul>
              <li>1000+ projets</li>
              <li>No transaction fees</li>
              <li>Unlimited Storage</li>
              <li>5 Download</li>
            </ul>
          </div>
          <div class="pricing-btn">
            <a href="#" class="btn">Start Now</a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="pricing-box active text-center mb-60">
          <div class="pricing-head">
            <h4>Professionl</h4>
            <div class="pricing-amount">
              <div class="annual_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 90 </span>
                <span class="subscription"> / Annual </span>
              </div>
              <div class="monthly_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 18 </span>
                <span class="subscription"> / Monthly </span>
              </div>
            </div>
            <h5>I have a dream</h5>
          </div>

          <div class="pricing-body mb-40 text-left">
            <ul>
              <li>1000+ projets</li>
              <li>No transaction fees</li>
              <li>Unlimited Storage</li>
              <li>5 Download</li>
            </ul>
          </div>
          <div class="pricing-btn">
            <a href="#" class="btn">Start Now</a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="pricing-box active text-center mb-60">
          <div class="pricing-head">
            <h4>Power Plan</h4>
            <div class="pricing-amount">
              <div class="annual_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 120 </span>
                <span class="subscription"> / Annual </span>
              </div>
              <div class="monthly_price">
                <sup><span class="currency">$</span></sup>
                <span class="price"> 25 </span>
                <span class="subscription"> / Monthly </span>
              </div>
            </div>
            <h5>I have a dream</h5>
          </div>
          <div class="pricing-body mb-40 text-left">
            <ul>
              <li>1000+ projets</li>
              <li>No transaction fees</li>
              <li>Unlimited Storage</li>
              <li>5 Download</li>
            </ul>
          </div>
          <div class="pricing-btn">
            <a href="#" class="btn">Start Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
