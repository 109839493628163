<section
  id="blog"
  class="blog-area p-relative pt-70"
  style="
    background-image: url(assets/images/landing/shape/header-sape8.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-7 col-lg-10">
        <div class="section-title text-center mb-50">
          <h2>Our Latest Blog & News</h2>
          <p>
            Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel
            scelerisque porttitor, leo quam vulputate nibh, sit amet blandit
            erat magna.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="single-post mb-30">
          <div class="blog-thumb">
            <a routerLink="blog/details"
              ><img src="assets/images/landing/blog/inner_b1.jpg" alt="img"
            /></a>
          </div>
          <div class="blog-content">
            <div class="b-meta mb-40">
              <ul>
                <li><a href="#">20 jan 2019</a></li>
              </ul>
            </div>
            <h4>
              <a routerLink="blog/details"
                >Making Distribut Product Team Work More With Monday</a
              >
            </h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisi cing elit sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div class="admin">
              <ul>
                <li>
                  <img
                    src="assets/images/landing/blog/admin-img.png"
                    alt="test"
                  />
                </li>
                <li>
                  <h6>Jhon Abraham</h6>
                  Author
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="single-post mb-30">
          <div class="blog-thumb">
            <a routerLink="blog/details"
              ><img src="assets/images/landing/blog/inner_b2.jpg" alt="img"
            /></a>
          </div>
          <div class="blog-content">
            <div class="b-meta mb-40">
              <ul>
                <li><a href="#">20 jan 2019</a></li>
              </ul>
            </div>
            <h4>
              <a routerLink="blog/details"
                >Monthly Web Development Upto Cost Of JavaScript Ethics</a
              >
            </h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisi cing elit sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div class="admin">
              <ul>
                <li>
                  <img
                    src="assets/images/landing/blog/admin-img.png"
                    alt="test"
                  />
                </li>
                <li>
                  <h6>Jhon Abraham</h6>
                  Author
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="single-post mb-30">
          <div class="blog-thumb">
            <a routerLink="blog/details"
              ><img src="assets/images/landing/blog/inner_b3.jpg" alt="img"
            /></a>
          </div>
          <div class="blog-content">
            <div class="b-meta mb-40">
              <ul>
                <li><a href="#">20 jan 2019</a></li>
              </ul>
            </div>
            <h4>
              <a routerLink="blog/details"
                >User Experience Psychology And Performance Smashing</a
              >
            </h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisi cing elit sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div class="admin">
              <ul>
                <li>
                  <img
                    src="assets/images/landing/blog/admin-img.png"
                    alt="test"
                  />
                </li>
                <li>
                  <h6>Jhon Abraham</h6>
                  Author
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
