import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private authfackservice: AuthfakeauthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add auth header with jwt if user is logged in and request is to the api url
      const user = this.authenticationService.userValue;
      const isLoggedIn = user && user.sellerToken;
      const isApiUrl = request.url.startsWith(environment.baseUrl);
      if (isLoggedIn && isApiUrl) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.sellerToken}`,
          },
        });
      }

      return next.handle(request);
    //   if (environment.defaultauth === "firebase") {
    //     const currentUser = this.authenticationService.currentUser();
    //     if (currentUser && currentUser.sellerToken) {
    //       request = request.clone({
    //         setHeaders: {
    //           Authorization: `Bearer ${currentUser.sellerToken}`,
    //         },
    //       });
    //     }
    //   } else {
    //     // add authorization header with jwt sellerToken if available
    //     const currentUser = this.authfackservice.currentUserValue;
    //     if (currentUser && currentUser.sellerToken) {
    //       request = request.clone({
    //         setHeaders: {
    //           Authorization: `Bearer ${currentUser.sellerToken}`,
    //         },
    //       });
    //     }
    //   }
    //   return next.handle(request);
    }
}
