import { Component } from '@angular/core';

@Component({
  selector: 'section-slider',
  templateUrl: './section-slider.component.html',
  styleUrls: ['./section-slider.component.scss']
})
export class SectionSliderComponent {

}
