import { Component } from '@angular/core';

@Component({
  selector: 'section-feature',
  templateUrl: './section-feature.component.html',
  styleUrls: ['./section-feature.component.scss']
})
export class SectionFeatureComponent {

}
