<section
  id="features"
  class="app-work pt-70 pb-100 p-relative"
  style="
    background-image: url(assets/images/landing/shape/header-sape4.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-6">
        <div class="choose-wrap">
          <div class="section-title w-title left-align mb-15">
            <h2>How does Clickz App Work?</h2>
          </div>
          <div class="app-work-content mt-20">
            <ul>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/apw-Icon1.png" />
                </div>
                <div class="text">
                  <h4>Register your Account</h4>
                  <p>
                    Signup for your account with email or use the easy apple or google signup options for free!
                  </p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/apw-Icon2.png" />
                </div>
                <div class="text">
                  <h4>Discover the Products & Deals</h4>
                  <p>
                    Easly navigate ready or customized products, services, special discounts, flash deals, and shops in few clicks!
                  </p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/apw-Icon3.png" />
                </div>
                <div class="text">
                  <h4>You’re Done! Checkout</h4>
                  <p>
                    Fill your cart and with our secure checkout process we ensure you a safe and hassle-free transaction!
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <img
          src="assets/images/landing/Section-4.png"
          alt="app-work-img"
          class="img"
        />
      </div>
    </div>
  </div>
</section>
