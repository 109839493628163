<layout-navbar></layout-navbar>

<main>
  <router-outlet></router-outlet>
</main>

<layout-footer></layout-footer>

<a
  id="scrollUp"
  class="cursor-pointer"
  (click)="scrollIntoTop()"
  style="position: fixed; z-index: 2147483647"
>
  <i class="fas fa-level-up-alt"></i>
</a>
