<section
  id="contact"
  class="contact-area contact-bg pt-50 pb-100 p-relative fix"
  style="
    background-image: url(assets/images/landing/shape/header-sape8.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="contact-img2">
          <img src="assets/images/landing/bg/illustration.png" alt="test" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="section-title mb-40">
          <h2>Let’s Get in Touch</h2>
          <p>
            Our user support team is waiting to assist you as soon as needed. Please, allow up to 2 business days’ response time for us to fully address your request. 
          </p>
        </div>
        <form action="#" class="contact-form">
          <div class="row">
            <div class="col-lg-12">
              <div class="contact-field p-relative c-name mb-20">
                <input type="text" placeholder="Name" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="contact-field p-relative c-email mb-20">
                <input type="text" placeholder="Email" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="contact-field p-relative c-subject mb-20">
                <input type="text" placeholder="Phone" />
              </div>
            </div>

            <div class="col-lg-12">
              <div class="contact-field p-relative c-message mb-45">
                <textarea
                  name="message"
                  id="message"
                  cols="10"
                  rows="10"
                  placeholder="Write comments"
                ></textarea>
              </div>
              <button class="btn">Send Message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
