<section class="video-area pt-100 pb-100 p-relative">
  <div class="video-img2">
    <!-- <img src="assets/images/landing/bg/video-img.png" alt="mobile" /> -->
    <img src="assets/images/landing/landing-video.png" alt="mobile" />


    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" class="popup-video"
      ><img src="assets/images/landing/bg/play-btn.png" alt="play-btn.png"
    /></a>
  </div>
  <div class="video-img3">
    <img
      src="assets/images/landing/shape/header-sape5.png"
      alt="header-sape4"
    />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xl-6"></div>
      <div class="col-xl-6">
        <div class="video-wrap">
          <div class="section-title w-title left-align mb-25">
            <h2>Would you like to be a Seller,
              Build your E-Store Now !
              </h2>
          </div>
          <div class="video-content">
            <p>
              Build your online store, create and customize your products and services list, link them with special offers and flash deals, promote and market them, and receive your bunch of orders smoothly.
            </p>
            <ul>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/vs-icon.png" />
                </div>
                <div class="text">
                  Store & Product Management
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/vs-icon.png" />
                </div>
                <div class="text">
                  Marketing & Sales Management
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="assets/images/landing/icon/vs-icon.png" />
                </div>
                <div class="text">
                  Orders & Transactions Management
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
