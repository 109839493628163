<div [formGroup]="parentForm" class="w-100">
  <label *ngIf="label" for="newpassword">{{ label }} </label>
  <div class="input-group auth-pass-inputgroup w-100">
    <input
      [type]="type"
      class="form-control"
      [ngClass]="{
        'is-invalid': ParentControl.touched && ParentControl.errors
      }"
      [pattern]="pattern"
      formControlName="{{ name }}"
      [placeholder]="placeholder"
    />
    <button
      *ngIf="isPassword"
      class="btn btn-light"
      type="button"
      (click)="setVisible()"
      id="password-addon"
    >
      <i class="mdi mdi-eye-outline"></i>
    </button>
    <div
      *ngIf="ParentControl.touched && ParentControl.errors"
      class="invalid-feedback"
    >
      <div *ngIf="ParentControl.errors.required">this field is required</div>
      <div
        *ngIf="
          ParentControl.errors.email &&
          ParentControl.errors.pattern.requiredPattern
        "
      >
        Email must be a valid email address
      </div>
      <div *ngIf="ParentControl.errors.minlength">
        {{ label }} should contain
        {{ ParentControl.errors.minlength.requiredLength }} characters, at least
      </div>
      <div *ngIf="ParentControl.errors.maxlength">
        {{ label }} should max length
        {{ ParentControl.errors.maxlength.requiredLength }} characters
      </div>
      <div *ngIf="ParentControl.errors.pattern">
        {{ label ? label : "this field" }} pattern is error
      </div>
    </div>
  </div>
</div>
