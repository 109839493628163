<section
  id="about"
  class="services-area services-bg pt-25 pb-20"
  style="
    background-image: url(assets/images/landing/shape/header-sape2.png);
    background-position: right top;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-7 col-lg-10">
        <div class="section-title text-center pl-40 pr-40 mb-45">
          <h2>Our Domains</h2>
          <p>
            Clickz unlocks the shopping experience for the products and services of its three main domains all in one place.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services active text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon1.png" />
          </div>
          <div class="second-services-content">
            <h5>Clickz Products</h5>
            <p>
              The variety of ready and hand-made products where you can shop for your clothes, electronics, and others.
            </p>
            <a href="#"><span>1</span></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon3.png" />
          </div>
          <div class="second-services-content">
            <h5>Traditional Food</h5>
            <p>
              Food from all around the world. Select your best meal and enjoy the taste of its country as if you were there.
            </p>
            <a href="#"><span>2</span></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon2.png" />
          </div>
          <div class="second-services-content">
            <h5>Customized Crafts</h5>
            <p>
              Customize your preferred products for painting, sewing, shoes repairs, carpentry, blacksmith, and many other crafts.
            </p>
            <a href="#"><span>3</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
