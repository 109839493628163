<section
  id="about"
  class="services-area services-bg pt-25 pb-20"
  style="
    background-image: url(assets/images/landing/shape/header-sape2.png);
    background-position: right top;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-7 col-lg-10">
        <div class="section-title text-center pl-40 pr-40 mb-45">
          <h2>Our Features</h2>
          <p>
            Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel
            scelerisque porttitor, leo quam vulputate nibh, sit amet blandit
            erat magna.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services active text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon1.png" />
          </div>
          <div class="second-services-content">
            <h5>Perfect UI Design</h5>
            <p>
              Praesent ac vehicula sapien. Sed sollicitudin molestie consequat.
              Ut vitae ante ut mi vehicula vulputate.
            </p>
            <a href="#"><span>1</span></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon3.png" />
          </div>
          <div class="second-services-content">
            <h5>Great CSS Animation</h5>
            <p>
              Praesent ac vehicula sapien. Sed sollicitudin molestie consequat.
              Ut vitae ante ut mi vehicula vulputate.
            </p>
            <a href="#"><span>2</span></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-30">
        <div class="s-single-services text-center">
          <div class="services-icon">
            <img src="assets/images/landing/icon/f-icon2.png" />
          </div>
          <div class="second-services-content">
            <h5>Fully Secured</h5>
            <p>
              Praesent ac vehicula sapien. Sed sollicitudin molestie consequat.
              Ut vitae ante ut mi vehicula vulputate.
            </p>
            <a href="#"><span>3</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
