import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'layout-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @ViewChild('nav') navElement!: ElementRef<HTMLElement>;

  mobileMenuShown: boolean = false;
  detectMobile: boolean = false;
  cookieValue;
  flagvalue;
  valueset;
  countryName;
  activeSectionId;

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    // { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    // { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    // { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    // { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
    { text: "Arabic", flag: "assets/images/flags/arabic.jpg", lang: "ar" },
  ];

  constructor(
    private router: Router,
    private _sharedService: SharedService,
    public _cookiesService: CookieService,
    public languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.checkUserAgent();

    this._sharedService.highlightedSection$.subscribe(
      (value) => (this.activeSectionId = value)
    );

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  checkUserAgent() {
    let ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      this.detectMobile = true;
    else
      this.detectMobile = this.detectMobile || false;

  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
  
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    window.pageYOffset <= 40
      ? this.unSetNavbarTransparent()
      : this.setNavbarTransparent();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    let screenWidth = window.innerWidth;
    this.detectMobile = screenWidth < 768;
  }

  setNavbarTransparent() {
    this.navElement.nativeElement.classList.add('sticky-menu');
  }

  unSetNavbarTransparent() {
    this.navElement.nativeElement.classList.remove('sticky-menu');
  }

  scrollInto(elementId: string): void {
    this.mobileMenuShown = false;
    this.router.navigate(['/'], { fragment: elementId });
  }

}
