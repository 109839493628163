import { Component } from '@angular/core';

@Component({
  selector: 'section-blog',
  templateUrl: './section-blog.component.html',
  styleUrls: ['./section-blog.component.scss']
})
export class SectionBlogComponent {

}
