import { Component } from '@angular/core';
import * as $ from 'jquery';// import Jquery here
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'section-testimonial',
  templateUrl: './section-testimonal.component.html',
  styleUrls: ['./section-testimonal.component.scss']
})
export class SectionTestimonalComponent {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true,
    stagePadding: 15,
    margin: 34,
    autoplay: true
  }


  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // testimonial-active
    // ($('.testimonial-active') as any).slick({
    //   dots: false,
    //   infinite: true,
    //   arrows: true,
    //   prevArrow: '<button type="button" class="slick-prev"><i class="icon dripicons-arrow-thin-left"></i></button>',
    //   nextArrow: '<button type="button" class="slick-next"><i class="icon dripicons-arrow-thin-right"></i></button>',
    //   speed: 1000,
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         infinite: true,
    //       }
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //       }
    //     }
    //   ]
    // });
  }

}
