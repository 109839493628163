import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingPageRoutingModule } from './landing-page-routing.module';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { MainComponent } from './pages/main/main.component';
import { SectionAboutComponent } from './pages/main/sections/section-about/section-about.component';
import { SectionFeatureComponent } from './pages/main/sections/section-feature/section-feature.component';
import { SectionServicesComponent } from './pages/main/sections/section-services/section-services.component';
import { SectionVideosComponent } from './pages/main/sections/section-videos/section-videos.component';
import { SectionSliderComponent } from './pages/main/sections/section-slider/section-slider.component';
import { SectionAreaComponent } from './pages/main/sections/section-area/section-area.component';
import { SectionFaqComponent } from './pages/main/sections/section-faq/section-faq.component';
import { SectionContactComponent } from './pages/main/sections/section-contact/section-contact.component';
import { SectionBlogComponent } from './pages/main/sections/section-blog/section-blog.component';
import { SectionTestimonalComponent } from './pages/main/sections/section-testimonal/section-testimonal.component';
import { SectionPricingComponent } from './pages/main/sections/section-pricing/section-pricing.component';
import { SectionNewsletterComponent } from './pages/main/sections/section-newsletter/section-newsletter.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SectionChooseAreaComponent } from './pages/main/sections/section-choose-area/section-choose-area.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MainComponent,
    BlogComponent,
    NavbarComponent,
    FooterComponent,
    LayoutsComponent,
    BlogDetailsComponent,
    SectionAboutComponent,
    SectionFeatureComponent,
    SectionServicesComponent,
    SectionVideosComponent,
    SectionSliderComponent,
    SectionAreaComponent,
    SectionFaqComponent,
    SectionContactComponent,
    SectionBlogComponent,
    SectionTestimonalComponent,
    SectionPricingComponent,
    SectionNewsletterComponent,
    SectionChooseAreaComponent, 
  ],
  imports: [
    CommonModule,
    CarouselModule,
    TranslateModule,
    NgbDropdownModule,
    LandingPageRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingPageModule { }
