import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

const DELIMITER = '-';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
*/
@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(DELIMITER);
      return {
        year: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? [date.year, pad(date.month), pad(date.day)].join(DELIMITER) : null;
  }
}
