import { Component, Input, OnInit } from '@angular/core';
import { ValidationService } from './validation.service';

@Component({
  selector: 'form-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

  errorMsg: string;
  @Input() field: string;

  constructor(private _validationService: ValidationService) { }

  ngOnInit(): void {
    this._validationService
      .formSubject
      .subscribe(object => 
        {
          setTimeout(() => {
            this.errorMsg = this._validationService.isFieldValid(object['form'], this.field, object['submit'])
          }, 0);
        }
      );
  }


}
