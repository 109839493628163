import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'section-pricing',
  templateUrl: './section-pricing.component.html',
  styleUrls: ['./section-pricing.component.scss']
})
export class SectionPricingComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    $('.pricing-tab-switcher').on('click', function () {
      $(this).toggleClass('active');

      $('.pricing-amount').toggleClass('change-subs-duration');
    });
  }

}
