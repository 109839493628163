import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";

import { EventService } from "../../core/services/event.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";

import { DOCUMENT } from "@angular/common";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { environment } from "../../../environments/environment";
import { UserProfileService } from "src/app/core/services/user.service";
import { SharedService } from "src/app/core/services/shared.service";
import { filter } from "rxjs";

@Component({
  selector: "app-horizontaltopbar",
  templateUrl: "./horizontaltopbar.component.html",
  styleUrls: ["./horizontaltopbar.component.scss"],
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent
  implements OnInit, AfterViewInit
{
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  loggedIn;
  seller: any;
  menuItems = [];
  user: any;
  listLandingPage = [
    { label: "Home", link: "/" },
    { label: "About Us", link: "/" },
    { label: "Features", link: "/" },
    { label: "Screenshorts", link: "/" },
    { label: "Contact Us", link: "/" },
  ];
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    // { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    // { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    // { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    // { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
    { text: "Arabic", flag: "assets/images/flags/arabic.jpg", lang: "ar" },
  ];

  mobileMenuShown: boolean = false;
  detectMobile: boolean = false;

  mainRoute: string = '';
  status: string = '';

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    private currentUser: UserProfileService,
    // tslint:disable-next-line: variable-name
    public _cookiesService: CookieService,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  checkUserAgent() {
    let ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      this.detectMobile = true;
    else
      this.detectMobile = this.detectMobile || false;
  }

  ngOnInit(): void {
    // this.seller = JSON.parse(localStorage.getItem("seller"));
    // if (!this.user && this.seller) {
    //   this.getCurrentUser();
    // }
    if(JSON.parse(localStorage.getItem('seller')))
    this.status = JSON.parse(localStorage.getItem('seller')).sellerStatus ?? '';
    this.checkUserAgent();
    this.element = document.documentElement;
    this.initialize();
    this.isLoggedIn();
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    
    this.mainRoute = this.router.url.split('/').filter(segment => segment !== '')[1];
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.mainRoute = event.url.split("/").filter(segment => segment !== '')[1];
      });
  }

  fetchRoute(
    route: string,
  ): boolean {
    if(route) {
      let splatRoute = route.split("/").filter(segment => segment !== '')[1]

      if(splatRoute == 'ad-wizard' && this.mainRoute == 'offer-wizard') {
        return true;
      }
      if(splatRoute == this.mainRoute) {
        return true;
      }
      return false;
    }
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   this.seller = JSON.parse(localStorage.getItem("seller"));
  //   if (!this.user && this.seller) {
  //     this.getCurrentUser();
  //   }
  // }
  // getCurrentUser() {
  //   this.currentUser
  //     .getUser(this.seller.sellerExternalId)
  //     .subscribe((data: any) => {
  //       this.user = data;
  //       this.loggedIn = true;
  //     });
  // }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * checked if logged in
   */
  isLoggedIn() {
    // console.log(this.authService.isLogged.value);
    return this.authService.haveUser.value
  }
  /**
   * Logout the user
   */
  // logout() {
  //   this.authService.logout();
  // }

  /**
   * On menu click
   */
  onMenuClick(
    event,
    parent?,
  ) {
    if(!this.detectMobile) {
      return;
    }

    if(parent) {
      let liElements = parent.querySelectorAll("li");
      liElements.forEach((li) => {
        // Check if the li element has a div
          var divElement = li.querySelector(".dropdown-menu");
          if (divElement && divElement.classList.contains("show") && (divElement !== event?.target.nextElementSibling)) {
            // Remove the class from the div
            divElement.classList.remove("show");
          }
        }
      );
    }
    
    const nextEl = event?.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }
    return false;
  }

  closeMenu(): void {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("collapsing");
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("show");
  }

  /**
   * Activates the menu
   */
  private activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove("active");
        const parent2 = parent.parentElement;
        this._removeAllClass("mm-active");
        this._removeAllClass("mm-show");
        if (parent2) {
          parent2.classList.remove("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove("active");
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("active");
                const menuelement = document.getElementById(
                  "topnav-menu-content"
                );
                if (menuelement !== null) {
                  if (menuelement.classList.contains("show"))
                    document
                      .getElementById("topnav-menu-content")
                      .classList.remove("show");
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName("side-nav-link-ref");
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]["pathname"]) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add("active");
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add("active");
                const parent6 = parent5.parentElement;
                if (parent6) {
                  parent6.classList.add("active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
