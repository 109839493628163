<section
  class="newslater-area pt-90 pb-100"
  style="
    background-image: url(assets/images/landing/bg/subscribe-bg.png);
    background-size: cover;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-10">
        <div class="section-title text-center pl-40 pr-40 mb-50">
          <h2>Be the First to Know! </h2>
          <p>
            Sign up for ClickZ emails to be the first to see and discover inspiring content, news, and exclusive offers. We’ll give you just the right amount of love.
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-10">
        <form
          name="ajax-form"
          id="contact-form4"
          action="#"
          method="post"
          class="contact-form newslater"
        >
          <div class="form-group">
            <input
              class="form-control"
              id="email2"
              name="email"
              type="email"
              placeholder="Email Address..."
              value=""
              required=""
            />
            <button type="submit" class="btn btn-custom" id="send2">
              Subscribe Now
            </button>
          </div>
          <!-- /Form-email -->
        </form>
      </div>
    </div>
  </div>
</section>
