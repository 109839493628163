<section
  id="parallax"
  class="slider-area slider-bg2 second-slider-bg d-flex fix"
  style="
    background-image: url(assets/images/landing/bg/pink-header-bg.png);
    background-position: right 0;
    background-repeat: no-repeat;
    background-size: 65%;
  "
>
  <div class="slider-shape ss-one layer" data-depth="0.10">
    <img src="assets/images/landing/shape/header-sape.png" alt="shape" />
  </div>

  <div class="slider-shape ss-eight layer" data-depth="0.50"></div>

  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="slider-content second-slider-content left-center">
          <ul class="small-title mb-30">
            <li>New</li>
            <li>Best Mobile App</li>
          </ul>
          <h2 data-animation="fadeInUp" data-delay=".4s">
            E-Shop with Confidence on our UAE 
              <span>Clickz</span>
          </h2>
          <p data-animation="fadeInUp" data-delay=".6s">
            Browse our vast selection of products from the comfort of your home and find everything you need with few Clicks.
          </p>
          <div class="slider-btn mt-30 mb-30">
            <a
              href="#"
              class="btn ss-btn"
              data-animation="fadeInUp"
              data-delay=".8s"
              >Install the App</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <img
          src="assets/images/landing/Section-1.png"
          style="transform: rotate(30deg); overflow: visible; filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.75));"
          alt="shape"
          class="s-img"
        />
      </div>
    </div>
  </div>
</section>
