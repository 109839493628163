import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { Swiper } from 'swiper';


@Component({
  selector: 'section-area',
  templateUrl: './section-area.component.html',
  styleUrls: ['./section-area.component.scss'],
})
export class SectionAreaComponent implements OnInit {

  ngOnInit(): void {
    const swiper = new Swiper('.swiper-container', {
      effect: 'coverflow',
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 0,
        stretch: 20,
        depth: 150,
        modifier: 1.5,
        slideShadows: true,
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

}
