import { Injectable } from "@angular/core";

import { getFirebaseBackend } from "../../authUtils";

import { User } from "../models/auth.models";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User | null>;
  haveUser: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public user: Observable<User | null>;
  constructor(private http: HttpClient, private router: Router) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("seller")!)
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }
  /**
   * Performs the auth
   * @param password password of user
   */
  forgetPassword(email: string) {
    return this.http.put(
      environment.baseUrl + `/clickz/seller/forget/${email}`,
      null
    );
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return this.http
      .post(environment.baseUrl + "/clickz/seller/sign-in", {
        sellerEmail: email,
        sellerPassword: password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("seller", JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */

  register(formData: any) {
    return this.http.post(
      environment.baseUrl + "/clickz/seller/sign-up",
      formData
    );
  }

  /**
   * @param sellerExternalId
   */
  submit(sellerExternalId) {
    return this.http.post(
      environment.baseUrl + "/clickz/seller/submit",
      sellerExternalId
    );
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(formData) {
    return this.http.put(
      environment.baseUrl + "/clickz/seller/forget/change",
      formData
    );
  }

  /**
   * verify message
   * @param OTP number
   */
  verityMessage(otp: string, id: String) {
    return this.http.put(environment.baseUrl + "/clickz/seller/verify", {
      sellerExternalId: id,
      sellerOtp: otp,
    });
  }

  /**
   * resend verify message
   * @param sellerExternalId string
   */
  resendVerityMessage(sellerExternalId) {
    return this.http
      .put(
        environment.baseUrl +
        `/clickz/seller/resend/${sellerExternalId}`,
        null
      )
      .subscribe((res) => { });
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage and set current user to null

    localStorage.clear();
    this.haveUser.next(false);
    this.userSubject.next(null);
    this.router.navigate(["/account/login"]);
  }
}
