<section
  class="faq-area pb-100"
  style="
    background-image: url(assets/images/landing/shape/header-sape6.png);
    background-position: right center;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="container">
    <div class="row align-items-end">
      <div class="col-lg-6">
        <div class="faq-img text-right">
          <img
            src="assets/images/landing/section-7.svg"
            alt="img"
            class="img"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="section-title left-align mb-50">
          <h2>Designed and Built to Cover Most
            Business Industries
            </h2>
          <p>
            ClickZ offers one place to find whatever you may need and want in any industry to be shipped to your preferred destination al around the world
          </p>
        </div>
        <div class="faq-wrap">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="faq-btn"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    What are the main categories covered in ClickZ?
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse show"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  ClickZ section provides you with an extensive list of products and services starting from fashion, home & kitchen, electronics, beauty & care, sports, pet supplies, office supplies & equipment, and many more.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="faq-btn collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    What differentiates ClickZ Food category?
                  </button>
                </h2>
              </div>
              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Navigating our ClickZ Food based on its nationality is meant to ease and smooth your searching experience, allowing you to discover the incredible taste of other countries made with home hands and with love.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="faq-btn collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How ClickZ Crafts is a unique value?
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Finding fully customized crafted products is always challenging when it comes to online shopping experience. Enjoy your purchases of Drawing & painting; sewing; shoes making, designing & repairing, carpentry, blacksmith, and much more.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
