import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UIModule } from "./ui/ui.module";
import { ValidationComponent } from "./validation/validation.component";
@NgModule({
  declarations: [ValidationComponent],
  imports: [CommonModule, UIModule],
  exports: [CommonModule, ValidationComponent, UIModule]
})
export class SharedModule { }
