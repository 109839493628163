import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  sections = ['home', 'aboutUs', 'features', 'screenshot', 'contact'];

  /**
   *
   */
  constructor(
    private _sharedService: SharedService,
  ) {
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const currentScrollPosition = window.pageYOffset;
    const sectionOffsets = this.sections.map(sectionId => {
      const sectionElement = document.getElementById(sectionId);
      return {
        id: sectionId,
        offset: sectionElement ? sectionElement.offsetTop : 0,
      };
    });
    
    // Find the active section based on the scroll position
    const activeSection = sectionOffsets.reduce((prev, curr) => {
      if (curr.offset <= currentScrollPosition && curr.offset > prev.offset) {
        return curr;
      }
      return prev;
    });

    this._sharedService.updateHighlightedSection(activeSection.id)

    // Apply the active class to the corresponding navigation item
    // this.sections.forEach(sectionId => {
    //   const navigationElement = document.getElementById(sectionId + '-nav');
    //   if (navigationElement) {
    //     navigationElement.classList.remove('active');
    //   }
    // });
    
    // const activeNavigationElement = document.getElementById(activeSection.id + '-nav');
    // if (activeNavigationElement) {
    //   activeNavigationElement.classList.add('active');
    // }
  }
}
