<!-- slider-area -->
<section-slider id="home"></section-slider>
<!-- slider-area-end -->

<!-- services-area -->
<section-about id="aboutUs"></section-about>
<!-- services-area-end -->

<!-- choose-area -->
<section-choose-area></section-choose-area>
<!-- choose-area-end -->

<!-- how-app-work -->
<section-feature id="features"></section-feature>
<!-- how-app-work -->

<!-- video-area -->
<section-video></section-video>
<!-- video-area-end -->

<!-- screen-area -->
<section-area id="screenshot"></section-area>
<!-- screen-area-end -->

<!-- faq-area -->
<section-faq></section-faq>
<!-- faq-aread-end -->

<!-- newslater-area -->
<section-newsletter id="contact"></section-newsletter>
<!-- newslater-aread-end -->

<!-- pricing-area -->
<section-pricing hidden></section-pricing>
<!-- pricing-area-end -->

<!-- testimonial-area -->
<section-testimonial hidden></section-testimonial>
<!-- testimonial-area-end -->

<!-- blog-area -->
<section-blog hidden></section-blog>
<!-- blog-area-end -->

<!-- contact-area -->
<section-contact></section-contact>
<!-- contact-area-end -->
