import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    link: "/dashboards/dashboard",
    icon: "bx-circle"
  },
  {
    id: 2,
    label: "MENUITEMS.STORE_MANAGEMENT.TEXT",
    link: "/dashboards/store-dashboard",
    icon: "bx-circle",
    // isUiElement: true,
    // subItems: [
    //   {
    //     id: 3,
    //     label: "MENUITEMS.STORE_MANAGEMENT.LIST.Store",
    //     link: "/dashboards/store-dashboard",
    //     parentId: 2,
    //   }
    // ],
  },
  {
    id: 4,
    label: "MENUITEMS.PRODUCT_MANAGEMENT.TEXT",
    icon: "bx-circle",
    link: "/dashboards/product-wizard",
    isUiElement: true,
    subItems: [
      {
        id: 5,
        label: "MENUITEMS.PRODUCT_MANAGEMENT.LIST.Wizard",
        link: "/dashboards/product-wizard",
        parentId: 4,
      },
      {
        id: 6,
        label: "MENUITEMS.PRODUCT_MANAGEMENT.LIST.Products",
        link: "/dashboards/product-wizard/products",
        parentId: 4,
      },
    ],
  },
  {
    id: 7,
    label: "MENUITEMS.MARKETING_MANAGEMENT.TEXT",
    icon: "bx-circle",
    link: "/dashboards/ad-wizard",
    isUiElement: true,
    subItems: [
      {
        id: 8,
        label: "MENUITEMS.MARKETING_MANAGEMENT.LIST.Advertisements",
        link: "/dashboards/ad-wizard/ads",
        parentId: 7,
      },
      {
        id: 9,
        label: "MENUITEMS.MARKETING_MANAGEMENT.LIST.Wizard",
        link: "/dashboards/ad-wizard",
        parentId: 7,
      },
      {
        id: 10,
        label: "MENUITEMS.MARKETING_MANAGEMENT.LIST.Offers",
        link: "/dashboards/offer-wizard/offers-list",
        parentId: 7,
      },
      {
        id: 11,
        label: "MENUITEMS.MARKETING_MANAGEMENT.LIST.OfferWizard",
        link: "/dashboards/offer-wizard",
        parentId: 7,
      },
    ],
  },
  {
    id: 12,
    label: "MENUITEMS.ORDER_MANAGEMENT.TEXT",
    icon: "bx-circle",
    isUiElement: true,
    subItems: [
      {
        id: 13,
        label: "MENUITEMS.ORDER_MANAGEMENT.LIST.Order",
        link: "/",
        parentId: 12,
      }
    ],
  },
];
