import { Component, Input, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  @Input() label: String="";
  @Input() name: FormControl;
  @Input() parentForm: UntypedFormGroup;
  @Input() errors;
  @Input() hasError: Boolean;
  @Input() type: String = "text";
  @Input() placeholder: String = "";
  @Input() isPassword = false;
  @Input() pattern = "";
  visible: Boolean = false;
  nameInput;
  constructor() {}
  ngOnInit(): void {
    this.nameInput = new String(this.name);
  }
  get ParentControl() {
    return this.parentForm.controls[this.nameInput.valueOf()];
  }

  setVisible() {
    this.visible = !this.visible;
    this.type = this.type === "password" ? "text" : "password";
  }
}
