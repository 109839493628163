<footer
  class="footer-bg footer-p pt-60"
  style="
    background-image: url(assets/images/landing/bg/f-bg.png);
    background-position: center top;
    background-size: auto;
    background-repeat: no-repeat;
  "
>
  <div class="footer-top">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="footer-widget mb-30">
            <div class="logo mt-15 mb-15 d-flex justify-content-start align-items-center ">
              <a href="#"
                ><img src="assets/images/favicon.ico" alt="logo" width="45"
              /></a>
              <a href="#"
                ><img src="assets/images/landing/ClickZ.png" alt="logo" width="154"
              /></a>
            </div>
            <div class="footer-text mb-20">
              <p>
                The ultimate online shopping destination, where convenience meets variety, and every click brings you closer to finding your perfect item.
              </p>
            </div>
            <div class="footer-social">
              <a href="#"><i class="fab fa-facebook-f"></i></a>
              <a href="#"><i class="fab fa-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
              <a href="#"><i class="fab fa-google-plus-g"></i></a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-sm-6">
          <div class="footer-widget mb-30">
            <div class="f-widget-title">
              <h5>Company</h5>
            </div>
            <div class="footer-link">
              <ul>
                <li><a href="#">Seller Registration</a></li>
                <li><a href="#">Terms of Use</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-sm-6">
          <div class="footer-widget mb-30">
            <div class="f-widget-title">
              <h5>Useful Links</h5>
            </div>
            <div class="footer-link">
              <ul>
                <li><a href="#">App Store</a></li>
                <li><a href="#">Play Store</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="footer-widget mb-30">
            <div class="f-widget-title">
              <h5>Contact Us</h5>
            </div>
            <div class="footer-link">
              <div class="f-contact">
                <ul>
                  <li>
                    <i class="icon dripicons-phone"></i>
                    <span>+971 503639355<br />+971 507974355</span>
                  </li>
                  <li>
                    <i class="icon dripicons-mail"></i>
                    <span
                      ><a href="mailto:info@clickz.live">info@clickz.live</a
                      ><br /><a href="mailto:seller-support@clickz.live"
                        >seller-support@clickz.live</a
                      ></span
                    >
                  </li>
                  <li>
                    <i class="fa fa-map-marker"></i>
                    <span
                      >Dubai<br />UAE</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-wrap text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="copyright-text">
            <p>&copy; 2024 @ Clickz. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
